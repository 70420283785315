// 建筑类型
export const architectureType = [
    {
        value: 1,
        label: "小区"
    },
    {
        value: 2,
        label: "楼幢"
    },
    {
        value: 3,
        label: "单元"
    }
];

// 小区管理 tableColumn
export const tableColumn = [
    {
        type: "index",
        label: "序号",
        width: 60
    },
    {
        prop: "name",
        label: "小区名称",
        width: 300,
        className: 'column-tree',
    },
    {
        prop: "type",
        label: "建筑类型",
        width: 100,
        render: (h, { row }) => {
            const { label } = architectureType.find(v => v.value === row.type);
            return h("span", label);
        }
    },
    {
        prop: "layerNum",
        label: "层级",
        width: 100,
    },
    {
        prop: "layerMax",
        label: "最大楼层数",
        width: 100,
    },
    {
        prop: "address",
        label: "地址",
    },
    {
        prop: "apartmentsCount",
        label: "房源总数",
        width: 100
    },
    /*{
        prop: "boundCount",
        label: "绑定房源数",
        width: 100
    },*/
    {
        prop: "rentingCount",
        label: "已租房源数",
        width: 100
    },
    {
        prop: "vacantCount",
        label: "空闲房源总数",
        width: 100
    },
    {
        prop: "houseManageName",
        label: "管家姓名",
        width: 100
    },
    {
        prop: "houseManagePhone",
        label: "管家手机号",
        width: 100
    },
];


// 新增、编辑小区的表单验证
export function formCommunityRules() {
    return {
        name: [
            { required: true, message: "请输入小区名称", trigger: "blur" },
        ],
        houseManageName: [
            { required: true, message: '请选择房管员姓名', trigger: 'blur' },
        ],
        houseManagePhone: [
            { required: true, message: '请输入房管员手机号码', trigger: 'blur' },
        ],
    };
}

// 新增、编辑楼幢、单元的表单验证
export function formBuildingRules() {
    return {
        number: [
            { required: true, message: "请输入编号", trigger: "blur" },
        ],
    };
}
