import {timeFormat, numberFormat} from "@custom";

export function tableColumn(_this) {
    return [
        {
            label: "姓名",
            render: (h, {row}) => {
                const {name} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click: () => _this.openDrawer(row),
                    },
                    style: {}
                }, name);
            },
        },
        {
            label: "电话",
            render: (h, {row}) => {
                const {tel1} = row;
                const leasorPhone = tel1.substring(0, 3) + "****" + tel1.substring(tel1.length - 4);
                return h("span", leasorPhone);
            },
        },
        {
            label: "身份证",
            render: (h, {row}) => {
                const {idcard} = row;
                const x = idcard.length - 8 === 10 ? "**********" : "********";
                const idcardText = idcard.substring(0, 4) + x + idcard.substring(idcard.length - 4);
                return h("span", idcardText);
            },
        },
        {
            prop: "address",
            label: "所在位置",

        },
        //    {
        //         prop: "smsNumber",
        //         label: "今日短信数量"
        //     },
        {
            label: "租金",
            render: (h, {row}) => {
                const {rentAmount} = row;
                return h("span", numberFormat(rentAmount / 100));
            },
        },
        {
            prop: "balance",
            label: "账户余额",
            render: (h, {row}) => {
                const {balance} = row;
                return h("span", numberFormat(balance / 100));
            },
        },
        {
            prop: "smsNumber",
            label: "今日发送短信数量",
            // render: (h, {row}) => {
            //     const {balance} = row;
            //     return h("span", numberFormat(balance / 100));
            // },
        }
    ];
}

// 租户详情 - 充值记录 - 表格
export const rechargeRecordColumns = [
    {
        label: "金额",
        width: 100,
        render: (h, {row}) => {
            const {rental_amount, expense_amount, depo_amount} = row;
            const amount = rental_amount + expense_amount + depo_amount;
            return numberFormat(amount / 100);
        },
    },
    {
        label: "支付时间",
        width: 150,
        render: (h, {row}) => {
            const {depositTimestamp} = row;
            return h("span", timeFormat(new Date(depositTimestamp), "yyyy-MM-dd HH:mm:ss"));
        },
    },
    {
        prop: "type",
        label: "类型",
        width: 70,
    },
    {
        prop: "paymentMethod",
        label: "支付方式",
        width: 70,
    },
    {
        prop: "comment",
        label: "备注",
        width: 150,
    },
];

// 租户详情 - 承租人详情 - 相关房源
export function relatedHousingColumns(that) {
    return [
        {
            label: "房源地址",
            render: (h, {row}) => {
                const {apartment: {name}} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$emit("closeDrawer");
                            that.$router.push({name: "house-resource", query: {data: {apartmerntName: name}}});
                        }
                    }
                }, name);
            }
        },
        {
            label: "合同编号",
            render: (h, {row}) => {
                const {contractNum} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$emit("closeDrawer");
                            that.$router.push({name: "individual-contract", params: {data: {contractNum}}});
                        }
                    }
                }, contractNum);
            }
        },
        {
            label: "合同状态",
            render: (h, {row}) => {
                const {contractStatusCode: code} = row;
                let type = "";
                let codeName = "";
                switch (code) {
                    case 500701:
                        type = "success";
                        codeName = "正在进行";
                        break;
                    case 500702:
                        type = "info";
                        codeName = "已到期";
                        break;
                    case 500703:
                        type = "warning";
                        codeName = "已结束";
                        break;
                    case 500704:
                        type = "";
                        codeName = "待生效";
                        break;
                    case 500705:
                        type = "danger";
                        codeName = "已作废";
                        break;
                    case 500706:
                        type = "success";
                        codeName = "已付款";
                        break;
                }
                return h("el-tag", {props: {type: type, size: "small"}}, codeName);
            }
        },
        {
            label: "合同周期",
            width: 200,
            render: (h, {row}) => {
                const {startDate, endDate} = row;
                return timeFormat(new Date(startDate)) + " 至 " + timeFormat(new Date(endDate));
            }
        },
    ];
}


// 房间详情 - 租房账单 - 表格
export function houseBillColumns(that) {
    return [
        {
            label: "账单周期",
            width: 200,
            render: (h, {row}) => {
                const {startDate, endDate} = row;
                return timeFormat(new Date(startDate)) + " 至 " + timeFormat(new Date(endDate));
            }
        },
        {
            label: "房源地址",
            prop: "apartment.name",
            render: (h, {row}) => {
                const {apartment: {name}} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$emit("closeDrawer");
                            that.$router.push({name: "house-resource", query: {data: {apartmerntName: name}}});
                        }
                    }
                }, name);
            }
        },
        {
            label: "账单状态",
            prop: "stateCodeName",
            width: 100,
            render: (h, {row}) => {
                const {stateCodeName, stateCode} = row;
                return h(
                    "el-tag",
                    {
                        props: {
                            type: stateCode === 600602 ? "success" : stateCode === 600601 ? "warning" : stateCode === 600604 ? 'info' : '',
                            size: "small"
                        }
                    },
                    stateCodeName
                );
            }
        },
        {
            label: "账单类型",
            prop: "typeName",
            width: 80,
            render: (h, {row}) => {
                const {typeName, stateCode, type, startDate, endDate, paymentChannel, paidDate, billNum} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$emit("closeDrawer");
                            that.$router.push({
                                name: "payment-record",
                                params: {
                                    data: {
                                        stateCode, type, startDate, endDate, paymentChannel, paidDate, keyWordCode: 1,
                                        keyWord: billNum
                                    }
                                }
                            });
                        }
                    }
                }, typeName);
            }
        },
        {
            label: "账单金额",
            prop: "amount",
            width: 100,
            render: (h, {row}) => {
                const {amount, stateCode} = row;
                return h("span", {
                    style: {
                        textDecoration: stateCode === 600604 ? 'line-through' : ''
                    }
                }, numberFormat(amount / 100));
            }
        },
        {
            label: "支付时间",
            width: 150,
            render: (h, {row}) => {
                let {paidDate} = row;
                paidDate = paidDate ? timeFormat(new Date(paidDate), "yyyy-MM-dd HH:mm:ss") : "";
                return h("span", paidDate);
            }
        },
        {
            label: "应收款日期",
            width: 100,
            render: (h, {row}) => {
                let {startDate} = row;
                return timeFormat(new Date(startDate));
            }
        },
    ];
}

// 房间详情 - 杂费账单 - 表格
export function houseOtherBillColumns(that) {
    return [
        {
            label: "账单周期",
            width: 200,
            render: (h, {row}) => {
                const {startDate, endDate} = row;
                return timeFormat(new Date(startDate)) + " 至 " + timeFormat(new Date(endDate));
            }
        },
        {
            label: "房源地址",
            render: (h, {row}) => {
                const {apartment: {name}} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$emit("closeDrawer");
                            that.$router.push({name: "house-resource", query: {data: {apartmerntName: name}}});
                        }
                    }
                }, name);
            }
        },
        {
            label: "账单状态",
            width: 100,
            render: (h, {row}) => {
                const {stateCodeName, stateCode} = row;
                return h(
                    "el-tag",
                    {
                        props: {
                            type: stateCode === 600602 ? "success" : stateCode === 600601 ? "warning" : stateCode === 600604 ? 'info' : '',
                            size: "small"
                        }
                    },
                    stateCodeName
                );
            }
        },
        {
            label: "账单类型",
            width: 100,
            render: (h, {row}) => {
                const {typeName, stateCode, type, startDate, endDate, paymentChannel, paidDate, billNum} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$emit("closeDrawer");
                            that.$router.push({
                                name: "payment-record",
                                params: {
                                    data: {
                                        stateCode, type, startDate, endDate, paymentChannel, paidDate, keyWordCode: 1,
                                        keyWord: billNum
                                    }
                                }
                            });
                        }
                    }
                }, typeName);
            }
        },
        {
            label: "账单金额",
            prop: "amount",
            width: 100,
            render: (h, {row}) => {
                const {amount, stateCode} = row;
                return h("span", {
                    style: {
                        textDecoration: stateCode === 600604 ? 'line-through' : ''
                    }
                }, numberFormat(amount / 100));
            }
        },
        {
            label: "支付时间",
            width: 150,
            render: (h, {row}) => {
                let {paidDate} = row;
                paidDate = paidDate ? timeFormat(new Date(paidDate), "yyyy-MM-dd HH:mm:ss") : "";
                return h("span", paidDate);
            }
        },
        {
            label: "应收款日期",
            width: 95,
            render: (h, {row}) => {
                const {startDate} = row;
                return timeFormat(new Date(startDate));
            }
        },
    ];
}

// 房间详情 - 消费记录 - 表格
export const callColumns = [
    {
        label: "时间",
        width: 200,
        render: (h, {row}) => {
            const {appleTime} = row;
            return timeFormat(new Date(appleTime), "yyyy-MM-dd HH:mm:ss");
        }
    },
    {
        prop: "typeName",
        label: "类型",
    },
    {
        prop: "",
        label: "充值/扣款",
        render: (h, {row}) => {
            const {amount} = row;
            return numberFormat(amount / 100);
        }
    },
    {
        prop: "",
        label: "余额",
        render: (h, {row}) => {
            const {balanceAmount} = row;
            return numberFormat(balanceAmount / 100);
        }
    },
];

// 房间详情 - 水电 - 表格
export function wtereleBillColumns(that) {
    return [
        {
            label: "房源地址",
            prop: "apartment.name",
            width: 220,
            render: (h, {row}) => {
                const {apartment: {name}} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$emit("closeDrawer");
                            that.$router.push({name: "door-management", query: {data: {apartmerntName: name}}});
                        }
                    }
                }, name);
            }
        },
        {
            prop: "waterAmount",
            label: "水费余额",
            width: 120,
            render: (h, {row}) => {
                const {waterAmount} = row;
                return numberFormat(waterAmount / 100);
            }
        },
        {
            label: "累计用水量",
            width: 120,
            render: (h, {row}) => {
                const {currentWater, lastWater} = row;
                return numberFormat(currentWater - lastWater);
            },
        },
        {
            prop: "electAmount",
            label: "电费余额",
            width: 120,
            render: (h, {row}) => {
                const {electAmount} = row;
                return numberFormat(electAmount / 100);
            }
        },
        {
            label: "累计用电量",
            width: 120,
            render: (h, {row}) => {
                const {currentElect, lastElect} = row;
                return numberFormat(currentElect - lastElect);
            }
        },
        {
            label: "状态",
            render: (h, {row}) => {
                const {statusCode} = row;
                let codeName = "";
                statusCode.forEach(element => {
                    const {codeValue} = element;
                    if (codeValue === 0) codeName += "正常";
                    if (codeValue === 800401) codeName += " 水费超量 ";
                    if (codeValue === 800402) codeName += " 电费超量 ";
                    if (codeValue >= 800501 && codeValue <= 800509) codeName += " 水余额不足 ";
                    if (codeValue >= 800601 && codeValue <= 800609) codeName += " 电余额不足 ";
                });
                return (codeName);
            }
        },
    ];
}

// 房间详情 - 水电详情 -表格
export const waterElectricityDetails = [
    {
        label: "时间",
        width: 200,
        render: (h, {row}) => {
            const {takeTime} = row;
            return timeFormat(new Date(takeTime), "yyyy-MM-dd HH:mm:ss");
        }
    },
    {
        prop: "waterCharge",
        label: "用水量",
        render: (h, {row}) => {
            const {currentWater, lastWater} = row;
            return numberFormat((currentWater - lastWater));
        }

    },
    {
        prop: "waterAmount",//放置处理后新增的字段
        label: "水费支出",
        render: (h, {row}) => {
            const {waterAmount} = row;
            return numberFormat(waterAmount / 100);
        }

    },
    {
        prop: "eleCharge",
        label: "用电量",
        render: (h, {row}) => {
            const {currentElect, lastElect} = row;
            return numberFormat((currentElect - lastElect));
        }

    },
    {
        prop: "electAmount",
        label: "电费支出",
        render: (h, {row}) => {
            const {electAmount} = row;
            return numberFormat(electAmount / 100);
        }
    },
];


// 2023/04/17 王珂 消息推送管理
export function tableWorkOrderColumn(that) {
    return [
        {
            label: "时间",
            prop: "timestamp",
            render: (h, {row}) => {
                const {timestamp} = row;
                return timeFormat(new Date(timestamp), "yyyy-MM-dd HH:mm:ss");
            }
        },
        {
            label: "租户",
            prop: "leasor.name",
        },
        {
            prop: "code",
            label: "消息类型",
            render: (h, {row}) => {
                let codeItem = that.masslist.find(({code}) => code === row.codeValue);
                return codeItem.codeName;
            }
        },
        {
            prop: "publishType",
            label: "推送渠道",
            render: (h, {row}) => {
                const {publishType} = row;
                let type = "";
                let codeName = "";
                switch (publishType) {
                    case 0:
                        codeName = "短信";
                        break;
                    case 1:
                        codeName = "小程序";
                        break;
                }
                return h("el-tag", {props: {type: type, size: "small"}}, codeName);
            }
        },
        {
            label: "状态",
            prop: "status",
            render: (h, {row}) => {
                const {status} = row;
                let type = "";
                let codeName = "";
                switch (status) {
                    case 0:
                        type = "warning";
                        codeName = "发送失败";
                        break;
                    case 1:
                        type = "success";
                        codeName = "已发送";
                        break;
                }
                return h("el-tag", {props: {type: type, size: "small"}}, codeName);
            }
        }
    ];
}

// 租户详情 - 报修管理 -表格
export const tableRepairColumn = [
    {
        prop: "innerMap.repairNo",
        label: "维修编号",
    },
    {
        prop: "innerMap.repairContent",
        label: "维修内容"
    },
    {
        prop: "innerMap.repairType",
        label: "类型",
        // width: 80,
    },
    {
        prop: "innerMap.repairName",
        label: "报修人",
        // width: 60,
    },
    {
        prop: "innerMap.createTime",
        label: "报修时间",
        // width: 100,
        render: (h, {row}) => {
            const {createTime} = row;
            return createTime ? timeFormat(new Date(createTime)) : "";
        }
    },
    {
        prop: "innerMap.repairProject",
        label: "房间"
    },
    {
        prop: "innerMap.repairMan",
        label: "维修人",
        // width: 80,
    },
    {
        prop: "innerMap.commentdate",
        label: "维修时间",
        // width: 100,
        render: (h, {row}) => {
            const {commentdate} = row;
            return commentdate ? timeFormat(new Date(commentdate)) : "";
        }
    },
    {
        prop: "innerMap.dealState",
        label: "跟进状态",
        // width: 100,
    },
    {
        prop: "innerMap.confirmor",
        label: "确认人",
        // width: 80,
    },
];

